import {createEffect} from 'effector'
import {ObjectType} from 'shared/helpers/types'
import {fetchAccessValue} from './services'

export const fetchAccessFx = createEffect(async (body: ObjectType) => {
    try {
        const response = await fetchAccessValue(body)
        if (response.errors) {
            throw new Error(response.errors?.[0]?.message)
        }
        return response?.data?.generalByName?.value
    } catch (error) {
        if (error instanceof Error) {
            throw new Error(error.message)
        }
    }
})
