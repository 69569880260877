import {lazy} from 'react'
import {Outlet} from 'react-router-dom'
import Loadable from 'components/Loadable'
import MainLayout from 'layout/MainLayout'

const TasksPage = Loadable(lazy(() => import('pages/extra-pages/tasks')))
const TaskPage = Loadable(lazy(() => import('pages/extra-pages/tasks/my-tasks/update-task/TaskOnePage')))
const CreateTaskPage = Loadable(lazy(() => import('pages/extra-pages/tasks/my-tasks/form/CreateTask')))

const AccountPage = Loadable(lazy(() => import('pages/extra-pages/account/AccountPage')))
const NewCustomer = Loadable(lazy(() => import('pages/extra-pages/account/create-customer')))
const AccountDetailsPage = Loadable(lazy(() => import('pages/extra-pages/account/account-details')))

const OpportunitiyPage = Loadable(lazy(() => import('pages/extra-pages/opportunities/OpportunitiesPage')))
const OpportunityDetailsPage = Loadable(lazy(() => import('pages/extra-pages/account/account-details/account-tab/opportunities/Opportunities')))

const PaymentPage = Loadable(lazy(() => import('pages/extra-pages/payment/PaymentPage')))
const SanctionPage = Loadable(lazy(() => import('pages/extra-pages/sanctioons/SanctionsPage')))
const TelematicPage = Loadable(lazy(() => import('pages/extra-pages/telematics/TelematicsPage')))

const UsersPage = Loadable(lazy(() => import('pages/extra-pages/users/UsersPage')))
const NewUsersPage = Loadable(lazy(() => import('pages/extra-pages/users/create-users/NewUsersPage')))
const UserPage = Loadable(lazy(() => import('pages/extra-pages/users/user-details/UserPage')))
const ProfilePage = Loadable(lazy(() => import('pages/extra-pages/profile/ProfilePage')))

const InsurePage = Loadable(lazy(() => import('pages/extra-pages/insures/InsurersPage')))
const InsurerDetailsPage = Loadable(lazy(() => import('pages/extra-pages/insures/insurers-tab')))
const NewInsurer = Loadable(lazy(() => import('pages/extra-pages/insures/form/InsurerTab')))
const NewInsurerAddress = Loadable(lazy(() => import('pages/extra-pages/insures/insurers-tab/entity/form/CreateEntity')))
const InsurerAddressDetailsPage = Loadable(lazy(() => import('pages/extra-pages/insures/insurers-tab/entity/form/EditEntity')))

const BackOfficePage = Loadable(lazy(() => import('pages/extra-pages/back-office/BackOfficePage')))

const FormBuilderPage = Loadable(lazy(() => import('pages/extra-pages/form-builder/FormBuilder')))

const QuestionLibraryPage = Loadable(lazy(() => import('pages/extra-pages/question-library/QuestionLibraryPage')))
const QuestionLibrarySheetsPage = Loadable(lazy(() => import('pages/extra-pages/question-library/QuestionLibrarySheets')))

const NotFoundPage = Loadable(lazy(() => import('pages/extra-pages/error-responses/NotFoundPages')))
const UnauthorizedPage = Loadable(lazy(() => import('pages/extra-pages/error-responses/UnauthorizedPage')))

const FormsPage = Loadable(lazy(() => import('pages/extra-pages/forms/Forms')))

const ProductsPage = Loadable(lazy(() => import('pages/extra-pages/products/Products')))
const ProductPage = Loadable(lazy(() => import('pages/extra-pages/products/Product')))

const FormWriter = Loadable(lazy(() => import('pages/extra-pages/form-builder/FormWriter')))

const InsuranceCategories = Loadable(lazy(() => import('pages/extra-pages/insurance-categories/InsuranceCategories')))
const SetPasswordPage = Loadable(lazy(() => import('pages/extra-pages/users/user-details/set-password')))

const MainRoutes = {
    path: '/',
    element: <MainLayout/>,
    children: [
        {
            path: 'tasks',
            element: <div><Outlet/></div>,
            children: [
                {
                    path: '',
                    element: <TasksPage/>
                },
                {
                    path: 'new-task',
                    element: <CreateTaskPage/>
                },
                {
                    path: 'task-details/:id',
                    element: <TaskPage/>
                }
            ]
        },
        {
            path: 'accounts',
            element: <div><Outlet/></div>,
            children: [
                {
                    path: '',
                    element: <AccountPage/>
                },
                {
                    path: 'new-account',
                    element: <NewCustomer/>
                },
                {
                    path: 'account-details/:id',
                    element: <AccountDetailsPage/>,
                    children: [
                        {
                            path: 'risk/:id',
                            element: <OpportunityDetailsPage/>
                        }
                    ]
                }
            ]
        },

        {
            path: 'opportunities',
            element: <OpportunitiyPage/>
        },

        {
            path: 'payments',
            element: <PaymentPage/>
        },

        {
            path: 'customer-due-diligence',
            element: <SanctionPage/>
        },

        {
            path: 'insurance-categories',
            element: <InsuranceCategories/>
        },

        {
            path: 'insurers',
            element: <div><Outlet/></div>,
            children: [
                {
                    path: '',
                    element: <InsurePage/>
                },
                {
                    path: 'new-insurer',
                    element: <NewInsurer/>
                },
                {
                    path: 'insurer-details/:id',
                    element: <InsurerDetailsPage/>,
                    children: [
                        {
                            path: 'address/:id',
                            element: <InsurerAddressDetailsPage/>
                        },
                        {
                            path: 'new-address',
                            element: <NewInsurerAddress/>
                        }
                    ]
                }
            ]
        },

        {
            path: 'telematics',
            element: <TelematicPage/>
        },
        {
            path: 'my-profile',
            element: <ProfilePage/>
        },
        {
            path: 'users',
            element: <div><Outlet/></div>,
            children: [
                {
                    path: '',
                    element: <UsersPage/>
                },
                {
                    path: 'new-user',
                    element: <NewUsersPage/>
                },
                {
                    path: 'user-details/:id',
                    element: <UserPage/>
                }
            ]
        },

        {
            path: 'back-office',
            element: <BackOfficePage/>
        },

        {
            path: 'question-library',
            element: <QuestionLibraryPage/>
        },

        {
            path: 'question-library/sheets',
            element: <QuestionLibrarySheetsPage/>
        },

        {
            path: 'products-details',
            element: <div><Outlet/></div>,
            children: [
                {
                    path: '',
                    element: <ProductsPage/>
                },
                {
                    path: ':id',
                    element: <ProductPage/>
                }
            ]
        },

        {
            path: 'forms',
            element: <div><Outlet/></div>,
            children: [
                {
                    path: '',
                    element: <FormsPage/>
                },
                {
                    path: ':id',
                    element: <FormBuilderPage/>
                }
            ]
        },

        {
            // path: "/:param([a-zA-Z0-9-]{8})",
            path: '/f/:id',
            element: <FormWriter/>
        },

        {
            path: '*',
            element: <NotFoundPage/>
        },

        {
            path: '/unauthorized',
            element: <UnauthorizedPage/>
        },
        {
            path: '/set-password',
            element: <SetPasswordPage/>
        }
    ]
}

export default MainRoutes