import { useMediaQuery } from '@mui/material'

export const useIsMobile = (size?: number): boolean => {
    return useMediaQuery(`(max-width:${size || 600}px)`)
}

export const useIsTablet = (size?: number): boolean => {
    return useMediaQuery(`(max-width:${size || 768}px)`)
}

export const useDynamicMediaSize = (size: number): boolean => {
    return useMediaQuery(`(max-width:${size}px)`)
}