import {createEvent, createStore} from 'effector'
import {ArrayType, ObjectType} from 'shared/helpers/types'
import {
    checkAuthFx,
    fetchAuthUserAttachmentsByIDFx,
    reSendVerifiedEmailCodeFx,
    sendCodeFx,
    setPasswordFx,
    signInFx,
    signUpFx
} from './effects'
import {UserType} from 'shared/model/users/users.type'

interface AuthState {
    isLoggedIn: boolean,
    isSignUp: boolean,
    isVerified: boolean,
    user: UserType,
    registerUser: UserType,
    avatar: string,
    attachments: ArrayType,
    forgotData: ObjectType,
    successFullyVerified: boolean,
    verifiedUserEmail: string
}

export const initialAuthState: AuthState = {
    isLoggedIn: false,
    isSignUp: false,
    isVerified: false,
    user: {} as UserType,
    registerUser: {} as UserType,
    avatar: '',
    attachments: [],
    forgotData: {
        email: '',
        code: ''
    },
    successFullyVerified: false,
    verifiedUserEmail: ''
}

export const $authState = createStore<AuthState>(initialAuthState)
export const resetAuthState = createEvent()
export const setSomeAuthState = createEvent<ObjectType>()

$authState
    .on(signInFx.done, (state, {result}) => ({
        ...state,
        user: result,
        verifiedUserEmail: result.email,
        isLoggedIn: result.verifyEmail,
        isVerified: result.verifyEmail,
        isSignUp: false
    }))

$authState
    .on(signInFx.fail, (state) => ({
        ...state,
        user: {} as UserType,
        isLoggedIn: false,
    }))

$authState
    .on(signUpFx.done, (state, {result}) => ({
        ...state,
        registerUser: result,
        verifiedUserEmail: result.email,
        isSignUp: true
    }))

$authState
    .on(checkAuthFx.done, (state, {result}) => {
        return ({
            ...state,
            user: result
        })
    })

$authState
    .on(resetAuthState, (state) => ({
        ...state,
        ...initialAuthState
    }))

$authState
    .on(setSomeAuthState, (state, {name, value}) => ({
        ...state,
        [name]: value,
    }))

$authState
    .on(fetchAuthUserAttachmentsByIDFx.done, (state, {result}) => {
        return {
            ...state,
            avatar: result?.path || '',
            attachments: result
        }
    })

$authState
    .on(sendCodeFx.done, (state, {result}) => {
        return ({
            ...state,
            forgotData: {
                ...state.forgotData,
                email: result
            }
        })
    })

$authState
    .on(setPasswordFx.done, (state, {result}) => ({
        ...state,
        user: {
            ...state.user,
            awsId: result.awsId
        }
    }))

$authState
    .on(reSendVerifiedEmailCodeFx.done, (state, {result}) => {
        return ({
            ...state,
            verifiedUserEmail: result
        })
    })