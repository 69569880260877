enum TaskStatus {
    PENDING = 'Pending',
    IN_PROGRESS = 'In progress',
    COMPLETED = 'Completed'
}

enum FilterByDate {
    PAST = 'Past',
    DAY = 'Day',
    WEEK = 'Week',
    MONTH = 'Month'
}

enum TaskPriority {
    HIGH = 'High',
    HIGHEST = 'Highest',
    MEDIUM = 'Medium',
    LOW = 'Low',
    LOWEST = 'Lowest',
}

enum TaskType {
    CREDIT_CHECK_QUERY = 'Credit Check Query',
    INCOME_TRACKER_QUERY = 'Income Tracker Query',
    INCOMING_MONEY_QUERY = 'Incoming Money Query',
    NORMAL = 'Normal',
    RECONCILIATION_QUERY = 'Reconciliation Query',
    TAM_RECONCILIATION_QUERY = 'Tam Reconciliation Query',
    RENEWAL_21_DAYS = 'Renewals - 21 Days',
    RENEWAL_14_DAYS = 'Renewals - 14 Days',
    RENEWAL_7_DAYS = 'Renewals - 7 Days',
    RENEWAL_TODAY = 'Renewal - Today',
    RENEWAL_PREPARATION = 'Renewal Preparation',
    SANCTIONS_QUERY = 'Sanctions Query',
    TAM_QUERY = 'TAM Query',
    PCL_QUERY = 'PCL Query',
    MID_TERM_ADJUSTMENT = 'Mid-Term Adjustment',
    OPPORTUNITY_RECONCILLIATION_QUERY = 'Opportunity Reconcilliation Query'
}

export const getTasksGQL = `
    query getTask(
        $page: Int = 1, 
        $size:Int = 10, 
        $where: TaskWhereInput,
        $dateFilter: FilterByDate, 
        $searchTerm: String, 
        $orderBy: [TaskOrderBy],
        $preferredFields: [String]
    ) {
        tasks(tasksInput: {
            page: $page, 
            size: $size, 
            where: $where,
            searchTerm: $searchTerm, 
            orderBy: $orderBy, 
            dateFilter: $dateFilter,
            preferredFields: $preferredFields
        }) {
            data {
                id
                status
                priority
                type
                title
                dueDate
                description
                createdDate
                updatedDate
                dueStatus
                customer {
                    id
                    soleTrader
                    privateIndividual
                    tradingName
                    registeredName
                }
                owner {
                    id
                    firstName
                    lastName
                }
                assignee {
                    id
                    firstName
                    lastName
                }   
            }
            count
        }
    }
`

export const getAllTasksGQL = `
    query getAllTask( 
        $size:Int = 100000, 
        $where: TaskWhereInput,
        $dateFilter: FilterByDate, 
        $searchTerm: String, 
        $orderBy: [TaskOrderBy]
    ) {
        tasks(tasksInput: { 
            size: $size, 
            where: $where,
            searchTerm: $searchTerm, 
            orderBy: $orderBy, 
            dateFilter: $dateFilter 
        }) {
            data {
                id
                status
                priority
                type
                title
                dueDate
                description
                createdDate
                updatedDate
                dueStatus
                owner {
                    id
                    firstName
                    lastName
                }
                customer {
                    id
                    soleTrader
                    privateIndividual
                    tradingName
                    registeredName
                }
                assignee {
                    id
                    firstName
                    lastName
                }   
            }
            count
        }
    }
`


export const getTasksCountGQL = `
    query getTasksCount($assigneeId: Int){
        taskCounts(assigneeId: $assigneeId){
            day_tasks_count
            month_tasks_count
            week_tasks_count
            overdue_tasks_count
            in_progress_tasks_count
            pending_tasks_count
            completed_tasks_count
            total_tasks_count
        }
    }
`

export const getDueTasksCountGQL = `
    query getDueTasksCounts($assigneeId: Int){
        dueTasksCounts(assigneeId: $assigneeId){
            total_tasks_count
            week_pending_tasks_count
            one_week_pending_tasks_count
            two_weeks_pending_tasks_count
            three_weeks_pending_tasks_count
            four_weeks_pending_tasks_count
            week_completed_tasks_count
            one_week_completed_tasks_count
            two_weeks_completed_tasks_count
            three_weeks_completed_tasks_count
            four_weeks_completed_tasks_count
            week_in_progress_tasks_count
            one_week_in_progress_tasks_count
            two_weeks_in_progress_tasks_count
            three_weeks_in_progress_tasks_count
            four_weeks_in_progress_tasks_count
        }
    }
`

export const getDueTasksCountsPeriodGQL = `
    query getDueTasksCountsPeriod($assigneeId: Int){
        dueTasksCountsPeriod(assigneeId: $assigneeId){
            day_tasks_count
            month_tasks_count
            week_tasks_count
            overdue_tasks_count
            total_tasks_count   
        }
    }
`

export const createNewTaskGQL = `
    mutation CreateTask(
        $createdBy: Int!
        $updatedBy: Int!
        $assigneeId: Int!
        $ownerId: Int!
        $customerId: Int!
        $status: TaskStatus!
        $priority: TaskPriority!
        $title: String!
        $type: TaskType!
        $dueDate: DateTime!
        $updateStatusDate: DateTime!
        $updateStatusBy: Int!
        $description: String!
    ){
        createTask(createTaskInput: {
            createdBy: $createdBy
            updatedBy: $updatedBy
            assigneeId: $assigneeId
            ownerId: $ownerId
            customerId: $customerId
            status: $status
            priority: $priority
            title: $title
            type: $type
            dueDate: $dueDate
            updateStatusDate: $updateStatusDate
            updateStatusBy: $updateStatusBy
            description: $description
        }){
            id
            status
            priority
            type
            dueDate
            description
            createdDate
            updatedDate
            title
            owner {
                id
                firstName
                lastName
            }
            assignee {
                id
                firstName
                lastName
            }
            customer {
                id
                tradingName
                registeredName
                soleTrader
                privateIndividual
            }
        }
    }
`

export const updatedTaskGQL = `
    mutation UpdateTask( 
        $id: Int!
        $updatedBy: Int!
        $title: String
        $assigneeId: Int
        $customerId: Int
        $status: TaskStatus
        $priority: TaskPriority
        $type: TaskType
        $dueDate: DateTime
        $updateStatusDate: DateTime
        $updateStatusBy: Int
        $description: String
    ){
        updateTask(updateTaskInput: {
            id: $id
            updatedBy: $updatedBy
            title: $title
            assigneeId: $assigneeId
            customerId: $customerId
            status: $status
            priority: $priority
            type: $type
            dueDate: $dueDate
            updateStatusDate: $updateStatusDate
            updateStatusBy: $updateStatusBy
            description: $description
        }){
            id
            status
            priority
            type
            dueDate
            description
            createdDate
            updatedDate
            title
            owner {
                id
                firstName
                lastName
            }
            assignee {
                id
                firstName
                lastName
            }
            customer {
                id
                tradingName
                registeredName
                soleTrader
                privateIndividual
            }
        }
    }
`

export const updatedTasksGQL = `
    mutation UpdateTaskInput( 
        $userId: Int
        $newUserId: Int
    ){
        updateTasks(
            userId: $userId
            newUserId: $newUserId
        ){
            count
        }
    }
`

export const getTaskByIDGQL = `
    query Task($id: Int = 1) {
        task(id: $id) {
            id
            status
            priority
            type
            dueDate
            description
            createdDate
            updatedDate
            createdBy
            title
             owner {
                id
                firstName
                lastName
            }
            assignee {
                id
                firstName
                lastName
            }
            customer {
                id
                tradingName
                registeredName
                soleTrader
                privateIndividual
            }
            dueStatus
            taskAttachments {
                id
                updatedBy
                createdBy
                createdDate
                updatedDate
                name
                path
                taskId
            }
        }
    }
`

export const removeTaskByIdGQL = `
    mutation RemoveTask( $id: Int = 1 ){
        removeTask(
            id: $id
        ) {
            id
        }
    }
`

export const removeTasksByIdGQL = `
    mutation RemoveTasks( $ids: [Int!] = [] ){
        removeTasks(
            ids: $ids
        ) {
            count
            deletedIds
        }
    }
`

export const removeTaskAttachmentByIdGQL = `
    mutation RemoveTaskAttachment( $id: Int! ){
        removeTaskAttachment (
            id: $id
        ) {
            id
        }
    }
`

export {
    TaskType,
    TaskStatus,
    FilterByDate,
    TaskPriority
}