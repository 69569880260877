import {
    createNewTaskGQL,
    getAllTasksGQL,
    getDueTasksCountGQL,
    getDueTasksCountsPeriodGQL,
    getTaskByIDGQL,
    getTasksCountGQL,
    getTasksGQL,
    removeTaskAttachmentByIdGQL,
    removeTaskByIdGQL,
    removeTasksByIdGQL,
    updatedTaskGQL,
    updatedTasksGQL
} from 'queries/tasks'
import {BASE_URL, Base_Url} from 'shared/helpers/api-routes'
import {ObjectType} from 'shared/helpers/types'
import {BaseApi} from 'utils/Api/baseApi'

export const fetchTasks = (body: ObjectType) => {
    const http = new BaseApi('')
    const gqlPayload = {
        query: getTasksGQL,
        variables: body
    }
    return http.post(Base_Url, gqlPayload)
}

export const fetchAllTasks = (body: ObjectType) => {
    const http = new BaseApi('')
    const gqlPayload = {
        query: getAllTasksGQL,
        variables: body
    }
    return http.post(Base_Url, gqlPayload)
}

export const fetchTaskCounts = (body: ObjectType) => {
    const http = new BaseApi('')
    const gqlPayload = {
        query: getTasksCountGQL,
        variables: body
    }
    return http.post(Base_Url, gqlPayload)
}

export const fetchDueTaskCounts = (body: ObjectType) => {
    const http = new BaseApi('')
    const gqlPayload = {
        query: getDueTasksCountGQL,
        variables: body
    }
    return http.post(Base_Url, gqlPayload)
}

export const fetchDueTasksCountsPeriod = (body: ObjectType) => {
    const http = new BaseApi('')
    const gqlPayload = {
        query: getDueTasksCountsPeriodGQL,
        variables: body
    }
    return http.post(Base_Url, gqlPayload)
}

export const fetchCreateTask = (body: ObjectType) => {
    const http = new BaseApi('')
    const gqlPayload = {
        query: createNewTaskGQL,
        variables: body
    }
    return http.post(Base_Url, gqlPayload)
}

export const fetchTaskById = (body: ObjectType) => {
    const http = new BaseApi('')
    const gqlPayload = {
        query: getTaskByIDGQL,
        variables: body
    }
    return http.post(Base_Url, gqlPayload)
}

export const fetchUpdateTaskByID = (body: ObjectType) => {
    const http = new BaseApi('')
    const gqlPayload = {
        query: updatedTaskGQL,
        variables: body
    }
    return http.post(Base_Url, gqlPayload)
}

export const fetchUpdatedTaskByID = (body: ObjectType) => {
    const http = new BaseApi('')
    const gqlPayload = {
        query: updatedTasksGQL,
        variables: body
    }
    return http.post(Base_Url, gqlPayload)
}

export const fetchRemoveTaskById = (body: ObjectType) => {
    const http = new BaseApi('')
    const gqlPayload = {
        query: removeTaskByIdGQL,
        variables: body
    }
    return http.post(Base_Url, gqlPayload)
}

export const fetchDeleteTasks = (body: ObjectType) => {
    const http = new BaseApi('')
    const gqlPayload = {
        query: removeTasksByIdGQL,
        variables: body
    }
    return http.post(Base_Url, gqlPayload)
}

export const fetchCreateDocumentList = (body: ObjectType) => {
    const http = new BaseApi('')
    return http.post(`${BASE_URL}/task-attachments/create`, body, {}, true)
}

export const fetchCreateTasksList = (body: ObjectType) => {
    const http = new BaseApi('')
    return http.post(`${BASE_URL}/tasks/create`, body, {}, true)
}

export const fetchRemoveDocumentById = (body: ObjectType) => {
    const http = new BaseApi('')
    const gqlPayload = {
        query: removeTaskAttachmentByIdGQL,
        variables: body
    }
    return http.post(Base_Url, gqlPayload)
}