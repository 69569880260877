import {createEffect} from 'effector'
import {
    fetchCreateInsurer,
    fetchDeleteInsurers,
    fetchInsurerByID,
    fetchInsurerLevelsByID,
    fetchInsurers,
    fetchRemoveInsurerById,
    fetchUpdateInsurerAvatarByID,
    fetchUpdateInsurerByID
} from './services'
import {ObjectType} from 'shared/helpers/types'
import {setSomeAuthState} from 'store/models/auth'
import {$insurerState} from 'store/models/insurer/index'

export const fetchInsurersFx = createEffect(async (body: ObjectType) => {
    try {
        const response = await fetchInsurers(body)
        if (response.errors) {
            throw new Error(response.errors?.[0]?.message)
        }
        return response?.data?.insurers || []
    } catch (error) {
        if (error instanceof Error) {
            throw new Error(error.message)
        }
    }
})

export const fetchInsurerByIDFx = createEffect(async (id: number) => {
    try {
        const response = await fetchInsurerByID({id})
        if (response.errors) {
            throw new Error(response.errors?.[0]?.message)
        }
        return response?.data?.insurer
    } catch (error) {
        if (error instanceof Error) {
            throw new Error(error.message)
        }
    }
})

export const fetchInsurerLevelsByIDFx = createEffect(async (insurerId: number) => {
    try {
        const response = await fetchInsurerLevelsByID({insurerId})
        if (response.errors) {
            throw new Error(response.errors?.fdsdfsd[0]?.message)
        }
        return response?.data?.insurerLevels || []
    } catch (error) {
        if (error instanceof Error) {
            throw new Error(error.message)
        }
    }
})

export const fetchUpdateInsurerByIDFx = createEffect(async (body: ObjectType) => {
    try {
        const response = await fetchUpdateInsurerByID(body)
        if (response.errors) {
            throw new Error(response.errors?.[0]?.message)
        }
        return response?.data?.updateInsurer
    } catch (error) {
        if (error instanceof Error) {
            throw new Error(error.message)
        }
    }
})

export const fetchCreateInsurerFx = createEffect(async (body: ObjectType) => {
    try {
        const response = await fetchCreateInsurer(body)
        if (response.errors) {
            throw new Error(response.errors?.[0]?.message)
        }
        return response?.data.createInsurer
    } catch (error) {
        if (error instanceof Error) {
            throw new Error(error.message)
        }
    }
})

export const fetchUpdateInsurerAvatarByIDFx = createEffect(async (body: ObjectType) => {
    try {
        const response = await fetchUpdateInsurerAvatarByID(body)
        if (response.errors || response.message) {
            throw new Error()
        }

        const {selectedInsurer} = $insurerState.getState()

        if (response.insurerId === selectedInsurer.id) {
            setSomeAuthState({name: 'INSURER_AVATAR', value: response.path})
        }

        return response || {}
    } catch (error) {
        if (error instanceof Error) {
            throw new Error(error.message)
        }
    }
})

export const fetchRemoveInsurerByIdFx = createEffect(async (body: ObjectType) => {
    try {
        const response = await fetchRemoveInsurerById(body)
        if (response.errors) {
            throw new Error(response.errors?.[0]?.message)
        }

        response.data.removeInsurer.forceDelete = body.forceDelete
        return response.data.removeInsurer
    } catch (error) {
        if (error instanceof Error) {
            throw new Error(error.message)
        }
    }
})

export const fetchDeleteInsurersFx = createEffect(async (body: ObjectType) => {
    try {
        const response = await fetchDeleteInsurers(body)
        if (response.errors) {
            throw new Error(response.errors?.[0]?.message)
        }

        // await fetchInsurersFx({
        //     size: defaultPerPage,
        // })

        return response.data.removeInsurers
    } catch (error) {
        if (error instanceof Error) {
            throw new Error(error.message)
        }
    }
})
