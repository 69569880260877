import {useEffect, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {Avatar, Box, ButtonBase, Grid, Skeleton, Stack, Typography} from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import {useCurrentUser} from 'utils/hooks/useCurrentUser'
import {fetchAuthUserAttachmentsByIDFx, logoutFx} from 'store/models/auth/effects'
import {useUnit} from 'effector-react'
import {$authState} from 'store/models/auth'
import useImageLoaded from 'utils/hooks/useImageLoadad'
import SkeletonLoad from 'shared/ui/skeleton/SkeletonLoad'
import {fetchUpdateUserAvatarByIDFx} from 'store/models/user/effects'
import {$userState} from 'store/models/user'

const Profile = () => {
    const anchorRef = useRef(null)
    const [open, setOpen] = useState(false) ////??????????
    const loading = useUnit(fetchAuthUserAttachmentsByIDFx.pending)
    const userData = useCurrentUser()
    const navigate = useNavigate()
    const {avatar, user} = useUnit($authState)
    const {error, selectedUser} = useUnit($userState)

    const handleLogout = () => {
        logoutFx().then(() => {
            return navigate('/login')
        })
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
        navigate('/my-profile')
    }

    // const iconBackColorOpen = 'grey.200'

    const {isLoaded, setIsLoaded} = useImageLoaded(avatar)

    const isPendingUpdate = useUnit(fetchUpdateUserAvatarByIDFx.pending)

    useEffect(() => {
        if (isPendingUpdate && user?.id === selectedUser?.id) setIsLoaded(false)
    }, [isPendingUpdate])

    const checkedSame = user?.id === selectedUser?.id ? !isPendingUpdate : true
    const skeletonCondition = checkedSame && isLoaded

    return (
        <Grid sx={{display: 'flex', alignItems: 'center', gap: '16px', flexShrink: 0, ml: 0.75}}>
            <ButtonBase
                sx={{
                    p: 0.25,
                    // bgcolor: open ? iconBackColorOpen : 'transparent',
                    borderRadius: 1
                }}
                aria-label="open profile"
                ref={anchorRef}
                // aria-controls={open ? 'profile-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <Stack direction="row" spacing={1} justifyContent="end" alignItems="center" sx={{p: 0.5}}>
                    <Box>
                        <SkeletonLoad
                            variant="circular"
                            height={38}
                            condition={avatar && !error ? skeletonCondition : true}
                        >
                            <Avatar
                                src={avatar || ''}
                                sx={{
                                    bgcolor: '#0c1330',
                                    width: 38,
                                    height: 38,
                                    fontSize: '18px'
                                }}
                            >{userData ? `${userData?.firstName?.charAt(0)}${userData?.lastName?.charAt(0)}` : 'User'}</Avatar>
                        </SkeletonLoad>
                    </Box>

                    <Box>
                        {loading ? (
                            <Skeleton width="180px">
                                <Typography>.</Typography>
                            </Skeleton>
                        ) : (
                            <Typography
                                >{userData ? `${userData.firstName} ${userData.lastName}` : 'User'}</Typography>
                        )}
                    </Box>
                </Stack>
            </ButtonBase>

            <Box
                sx={{cursor: 'pointer'}}
                onClick={() => handleLogout()}
            >
                <LogoutIcon color="secondary"/>
            </Box>
        </Grid>
    )
}

export default Profile