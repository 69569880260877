export const CustomerAddressType = {
    ADDITIONAL: 'Additional',
    CORRESPONDENCE: 'Correspondence',
    OPERATING: 'Operating',
    REGISTERED: 'Registered'
}

export const PersonTitle = {
    DAME: 'Dame',
    DR: 'Dr',
    ESQ: 'Esq',
    LADY: 'Lady',
    LORD: 'Lord',
    MASTER: 'Master',
    MISS: 'Miss',
    MR: 'Mr',
    MRS: 'Mrs',
    MS: 'Ms',
    MX: 'Mx',
    PROFESSOR: 'Professor',
    SIR: 'Sir'
}

export const AccountType = {
    SOLE_TRADER: 'Sole Trader',
    COMPANY: 'Company',
    PARTNERSHIP: 'Partnership',
    INDIVIDUAL: 'Individual'
}

export const CompanyType = {
    ASSOCIATION_CLUB_SOCIETY: 'Association/Club/Society',
    CHARITY: 'Charity',
    GOVERNMENT: 'Government',
    LIMITED_LIABILITY_PARTNERSHIP: 'Limited Liability Partnership',
    PARTNERSHIP_LIMITED: 'Partnership - Limited',
    PARTNERSHIP_ORDINARY: 'Partnership - Ordinary',
    PRIVATE_LIMITED_COMPANY: 'Private Limited Company',
    PRIVATE_UNLIMITED_COMPANY: 'Private Unlimited Company',
    PUBLIC_LIMITED_COMPANY: 'Public Limited Company',
    SOLE_PROPRIETOR: 'Sole Proprietor',
    TRUST_OR_TRUSTEE: 'Trust or Trustee'
}

//Customer
export const getCustomersGQL = `
    query getCustomers(
        $page: Int = 1, 
        $size:Int = 100, 
        $showActive: Boolean, 
        $searchTerm: String, 
        $preferredFields: [String],
        $ownerId: Int,
        $orderBy: [CustomerOrderBy]
    ) {
      customers(
        page: $page, 
        size: $size, 
        showActive: $showActive, 
        searchTerm: $searchTerm, 
        preferredFields: $preferredFields,
        ownerId: $ownerId,
        orderBy: $orderBy
    ) { 
        data {
            id
            tradingName
            registeredName
            phoneNumber
            companyNumber
            email
            companyType {
                id
                label
                value
            }
            url
            status
            businessDescriptionDetail
            registeredAddress
            soleTrader
            privateIndividual
            sicCode
            incorporatedDate
            startedTradingDate
            sicCodeDescription
            active
            _count {
                tasks
            }
            risks {
                id
                uuid                
            }
            tasks {
                id
                title
            }
        }
        count
      }
    }
`

export const getCustomerByIDGQL = `
    query Customer($id: Int = 1) {
        customer(id: $id) {
            id
            tradingName
            registeredName
            phoneNumber
            email
            companyType {
                id
                label
                value
            }
            url
            status
            businessDescriptionDetail
            registeredAddress
            soleTrader
            privateIndividual
            sicCode
            incorporatedDate
            companyNumber
            startedTradingDate
            sicCodeDescription
            active
            businessDescription {
                id
                label
            }
            owner {
                id
                lastName
                firstName
            }        
            tasks {
                id
                status
                priority
                type
                dueDate
                description
                createdDate
                updatedDate
                title
                 owner {
                    id
                    firstName
                    lastName
                }
                assignee {
                    id
                    firstName
                    lastName
                }
                customer {
                    id
                    tradingName
                    registeredName
                }
            }    
            risks {
                id
                uuid                
            }
        }
    }
`

export const createCustomerGQL = `
    mutation CreateCustomer(
        $createdBy: Int!
        $ownerId: Int
        $tradingName: String
        $registeredName: String = "N/A"
        $phoneNumber: String!
        $email: String
        $companyTypeId: Int
        $url: String
        $status: String
        $businessDescriptionDetail: String
        $registeredAddress: String
        $soleTrader: Boolean
        $privateIndividual: Boolean
        $sicCode: String
        $incorporatedDate: DateTime
        $sicCodeDescription: String
        $companyNumber: String
        $businessDescriptionId: Int
        $startedTradingDate: DateTime
    ){
        createCustomer(createCustomerInput: {
            createdBy: $createdBy
            ownerId: $ownerId
            tradingName: $tradingName
            registeredName: $registeredName
            phoneNumber: $phoneNumber
            email: $email
            companyTypeId: $companyTypeId
            url: $url
            status: $status
            businessDescriptionDetail: $businessDescriptionDetail
            registeredAddress: $registeredAddress
            soleTrader: $soleTrader
            privateIndividual: $privateIndividual
            sicCode: $sicCode
            incorporatedDate: $incorporatedDate
            sicCodeDescription: $sicCodeDescription
            companyNumber: $companyNumber
            businessDescriptionId: $businessDescriptionId
            startedTradingDate: $startedTradingDate
        }){
            id
        }
    }
`

export const updateCustomerByIdGQL = `
    mutation UpdateCustomer(
        $id: Int!
        $updatedBy: Int!
        $updatedDate: DateTime!
        $tradingName: String
        $registeredName: String
        $phoneNumber: String
        $email: String
        $companyTypeId: Int
        $url: String
        $status: String
        $businessDescriptionDetail: String
        $registeredAddress: String
        $soleTrader: Boolean
        $privateIndividual: Boolean
        $incorporatedDate: DateTime
        $sicCode: String
        $sicCodeDescription: String
        $companyNumber: String
        $ownerId: Int
        $businessDescriptionId: Int
        $startedTradingDate: DateTime
        $active: Boolean
    ){
        updateCustomer(updateCustomerInput: {
            id: $id
            updatedBy: $updatedBy
            updatedDate: $updatedDate
            tradingName: $tradingName
            registeredName: $registeredName
            phoneNumber: $phoneNumber
            email: $email
            companyTypeId: $companyTypeId
            url: $url
            status: $status
            businessDescriptionDetail: $businessDescriptionDetail
            registeredAddress: $registeredAddress
            soleTrader: $soleTrader
            privateIndividual: $privateIndividual
            incorporatedDate: $incorporatedDate
            sicCode: $sicCode
            sicCodeDescription: $sicCodeDescription
            companyNumber: $companyNumber
            ownerId: $ownerId
            businessDescriptionId: $businessDescriptionId
            startedTradingDate: $startedTradingDate
            active: $active
        }){
            id
            tradingName
            registeredName
            phoneNumber
            email
            companyType {
                id
                label
                value
            }
            url
            status
            businessDescriptionDetail
            registeredAddress
            soleTrader
            privateIndividual
            sicCode
            incorporatedDate
            sicCodeDescription
        }
    }
`

export const removeCustomerByIdGQL = `
    mutation RemoveCustomer( 
        $id: Int
        $newId: Int
        $forceDelete: Boolean
    ){
        removeCustomer(
            id: $id
            newId: $newId
            forceDelete: $forceDelete
        ) {
            id
            active
        }
    }
`

export const removeCustomersGQL = `
    mutation RemoveCustomers( $ids: [Int!] = [], $forceDelete: Boolean = false ){
        removeCustomers(
            ids: $ids
            forceDelete: $forceDelete
        ) {
            count   
        }
    }
`