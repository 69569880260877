import {createEffect} from 'effector'
import {ObjectType} from 'shared/helpers/types'
import {
    fetchAllTasks,
    fetchCreateDocumentList,
    fetchCreateTask,
    fetchCreateTasksList,
    fetchDeleteTasks,
    fetchDueTaskCounts,
    fetchDueTasksCountsPeriod,
    fetchRemoveDocumentById,
    fetchRemoveTaskById,
    fetchTaskById,
    fetchTaskCounts,
    fetchTasks,
    fetchUpdatedTaskByID,
    fetchUpdateTaskByID
} from './services'
import {$userState, setUserState} from '../user'
import {UserType} from 'shared/model/users/users.type'

export const fetchTasksFx = createEffect(async (body: ObjectType) => {
    try {
        const response = await fetchTasks(body)
        if (response.errors) {
            throw new Error(response.errors?.[0]?.message)
        }
        return response.data.tasks
    } catch (error) {
        if (error instanceof Error) {
            throw new Error(error.message)
        }
    }
})

export const fetchAllTasksFx = createEffect(async (body: ObjectType) => {
    try {
        const response = await fetchAllTasks(body)

        if (response.errors) {
            throw new Error(response.errors?.[0]?.message)
        }
        return response.data.tasks
    } catch (error) {
        if (error instanceof Error) {
            throw new Error(error.message)
        }
    }
})

export const fetchTaskCountsFx = createEffect(async (assigneeId: number | null) => {
    try {
        const response = await fetchTaskCounts({assigneeId})
        if (response.errors) {
            throw new Error(response.errors?.[0]?.message)
        }
        return response.data.taskCounts
    } catch (error) {
        if (error instanceof Error) {
            throw new Error(error.message)
        }
    }
})

export const fetchDueTaskCountsFx = createEffect(async (assigneeId: number | null) => {
    try {
        const response = await fetchDueTaskCounts({assigneeId})
        if (response.errors) {
            throw new Error(response.errors?.[0]?.message)
        }
        return response.data.dueTasksCounts
    } catch (error) {
        if (error instanceof Error) {
            throw new Error(error.message)
        }
    }
})

export const fetchDueTasksCountsPeriodFx = createEffect(async (assigneeId: number | null) => {
    try {
        const response = await fetchDueTasksCountsPeriod({assigneeId})
        if (response.errors) {
            throw new Error(response.errors?.[0]?.message)
        }
        return response.data.dueTasksCountsPeriod
    } catch (error) {
        if (error instanceof Error) {
            throw new Error(error.message)
        }
    }
})

export const fetchCreateTaskFx = createEffect(async (body: ObjectType) => {
    try {
        const response = await fetchCreateTask(body)
        if (response.errors) {
            throw new Error(response.errors?.[0]?.message)
        }
        return response.data.createTask
    } catch (error) {
        if (error instanceof Error) {
            throw new Error(error.message)
        }
    }
})

export const fetchTaskByIdFx = createEffect(async (body: ObjectType) => {
    try {
        const response = await fetchTaskById(body)
        if (response?.data?.task) {
            return response.data.task
        }
        return {}
    } catch (error) {
        if (error instanceof Error) {
            throw new Error(error.message)
        }
    }
})

export const fetchUpdateTaskByIDFx = createEffect(async (body: ObjectType) => {
    try {
        const response = await fetchUpdateTaskByID(body)
        if (response.errors) {
            throw new Error(response.errors?.[0]?.message)
        }
        return response.data.updateTask
    } catch (error) {
        if (error instanceof Error) {
            throw new Error(error.message)
        }
    }
})

export const fetchUpdatedTaskByIDFx = createEffect(async (body: ObjectType) => {
    try {
        const {users} = $userState.getState()
        const clonedUsers = structuredClone(users)
        const idx = clonedUsers.findIndex((user: UserType) => user.id === body.newUserId)

        if (idx > 0) {
            const user = clonedUsers[idx]
            user.taskCount = isNaN(user?.taskCount) ? body?.taskCount : body?.taskCount + user?.taskCount
            clonedUsers[idx] = user
            setUserState({name: 'users', value: clonedUsers})
        }

        const response = await fetchUpdatedTaskByID(body)
        if (response.errors) {
            throw new Error(response.errors?.[0]?.message)
        }

        return response.data.updateTasks
    } catch (error) {
        if (error instanceof Error) {
            throw new Error(error.message)
        }
    }
})

export const fetchRemoveTaskByIdFx = createEffect(async (taskId: number) => {
    try {
        const response = await fetchRemoveTaskById({id: taskId})
        if (response.errors) {
            throw new Error(response.errors?.[0]?.message)
        }
        return response.data.removeTask
    } catch (error) {
        if (error instanceof Error) {
            throw new Error(error.message)
        }
    }
})

export const fetchDeleteTasksFx = createEffect(async (body: ObjectType) => {
    try {
        const response = await fetchDeleteTasks(body)
        if (response.errors) {
            throw new Error(response.errors?.[0]?.message)
        }

        await fetchTaskCountsFx(null)

        return response.data.removeTasks
    } catch (error) {
        if (error instanceof Error) {
            throw new Error(error.message)
        }
    }
})

export const fetchCreateDocumentFx = createEffect(async (body: ObjectType) => {
    try {
        const response = await fetchCreateDocumentList(body)

        if (response?.errors) throw new Error(response?.errors?.[0]?.message)
        if (response?.message) throw new Error(response?.message)

        return response
    } catch (error) {
        if (error instanceof Error) {
            throw new Error(error.message)
        }
    }
})

export const fetchCreateDocumentAndTaskFx = createEffect(async (body: ObjectType) => {
    try {
        const response = await fetchCreateTasksList(body)

        if (response?.errors) throw new Error(response?.errors?.[0]?.message)
        if (response?.message) throw new Error(response?.message)

        return response
    } catch (error) {
        if (error instanceof Error) {
            throw new Error(error.message)
        }
    }
})

export const fetchRemoveDocumentByIdFx = createEffect(async (id: number) => {
    try {
        const response = await fetchRemoveDocumentById({id})
        if (response.errors) {
            throw new Error(response.errors?.[0]?.message)
        }
        return response.data.removeTaskAttachment
    } catch (error) {
        if (error instanceof Error) {
            throw new Error(error.message)
        }
    }
})