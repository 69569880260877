export enum TypeOfInsurer {
    INSURER = 'Insurer',
    MGA = 'MGA',
    WHOLESALE_BROKER = 'Wholesale Broker'
}

export enum Rating {
    A = "A",
    A_MINUS = "A-",
    A_PLUS = "A+",
    AA = "AA",
    AA_MINUS = "AA-",
    AA_PLUS = "AA+",
    AAA = "AAA",
    B = "B",
    B_MINUS = "B-",
    B_PLUS = "B+",
    BB = "BB",
    BB_MINUS = "BB-",
    BB_PLUS = "BB+",
    BBB = "BBB",
    BBB_MINUS = "BBB-",
    BBB_PLUS = "BBB+",
    C = "C",
    CC = "CC",
    CCC = "CCC",
    CCC_MINUS = "CCC-",
    CCC_PLUS = "CCC+",
    D = "D",
    NOT_RATED = "Not Rated"
}

export enum AttachmentsType {
    AVATAR = 'AVATAR',
    INSURER_AVATAR = 'INSURER_AVATAR',
    FORM_RESPONSE = 'FORM_RESPONSE',
    OTHER = 'OTHER',
}

export const getInsurersGQL = `
    query getInsurer(
        $page: Int = 1, 
        $size:Int = 10, 
        $searchTerm: String, 
        $showActive: Boolean,
        $orderBy: [InsurerOrderBy],
        $preferredFields: [String]
    ) {
        insurers(
            page: $page, 
            size: $size, 
            searchTerm: $searchTerm, 
            showActive: $showActive,
            orderBy: $orderBy,
            preferredFields: $preferredFields
        ) {
            data {
                id
                registeredName
                companyNumber
                name
                fcaNumber
                rating
                active
                typeOfInsurer
                mga
                description
                domain
                registrationAddress
                premiumCreditCode       
            }
            count
        }
    }
`

export const getInsurerByIDGQL = `
    query Insurer($id: Int!) {
        insurer(id: $id) {
            id
            registeredName
            companyNumber
            name
            fcaNumber
            rating
            typeOfInsurer
            mga
            description
            domain
            registrationAddress
            premiumCreditCode
            active
            createdBy
        }  
    }
`

export const getInsurerLevelsByIDGQL = `
    query InsurerLevels($insurerId: Int!) {
        insurerLevels(insurerId: $insurerId) {
            id
            name
            rating
            levelsTwo {
                id
                name
            }
        }  
    }
`

export const updatedInsurerGQL = `
    mutation UpdateInsurer( 
        $id: Int!
        $updatedBy: Int! 
        $name: String
        $typeOfInsurer: TypeOfInsurer
        $registeredName: String
        $companyNumber: String
        $fcaNumber: String
        $rating: Rating
        $mga: Boolean
        $description: String
        $domain: String
        $registrationAddress: String
        $premiumCreditCode: String
        $active: Boolean
    ){
        updateInsurer(updateInsurerInput: {
            id: $id
            updatedBy: $updatedBy
            name: $name
            typeOfInsurer: $typeOfInsurer
            registeredName: $registeredName
            companyNumber: $companyNumber
            fcaNumber: $fcaNumber
            rating: $rating
            mga: $mga
            description: $description
            domain: $domain
            registrationAddress: $registrationAddress
            premiumCreditCode: $premiumCreditCode
            active: $active 
        }){
            id
            registeredName
            companyNumber
            name
            fcaNumber
            rating
            typeOfInsurer
            mga
            description
            domain
            registrationAddress
            premiumCreditCode
            active
        }
    }
`

export const createInsurerGQL = `
    mutation CreateInsurer(
        $createdBy: Int!
        $name: String!
        $typeOfInsurer: TypeOfInsurer
        $registeredName: String
        $companyNumber: String
        $fcaNumber: String
        $rating: Rating
        $mga: Boolean
        $description: String
        $domain: String
        $registrationAddress: String
        $premiumCreditCode: String
    ){
        createInsurer(createInsurerInput: {
            createdBy: $createdBy
            name: $name
            typeOfInsurer: $typeOfInsurer
            registeredName: $registeredName
            companyNumber: $companyNumber
            fcaNumber: $fcaNumber
            rating: $rating
            mga: $mga
            description: $description
            domain: $domain 
            registrationAddress: $registrationAddress
            premiumCreditCode: $premiumCreditCode
        }){
            id
            registeredName
            companyNumber
            name
            fcaNumber
            rating
            typeOfInsurer
            mga
            description
            domain
            registrationAddress
            premiumCreditCode
            active
        }
    }
`

export const removeInsurerByIdGQL = `
    mutation RemoveInsurer( $id: Int!, $forceDelete: Boolean ){
        removeInsurer(
            id: $id
            forceDelete: $forceDelete
        ) {
            id
        }
    }
`

export const fetchInsurerAvatarByIDGQL = `
    query UserAttachmentByUserID($id: Int = 1, $type: AttachmentsType!) {
        attachmentByID(
            id: $id,
            type: $type
        ) {
            id
            type
            path
        }
    }
`

export const createInsurerCategoryGQL = `
    mutation CreateInsurerCategory(
        $createdBy: Int!
        $name: String!
        $active: Boolean
        $categoryTypes: String
    ){
        createInsurerCategory(createInsurerCategoryInput: {
            createdBy: $createdBy
            name: $name
            active: $active
            categoryTypes: $categoryTypes
        }){
            id
            name
            active
            categoryTypes
        }
    }
`

export const removeInsurerCategoryGQL = `
    mutation RemoveInsurerCategory( $id: Int! ){
        removeInsurerCategory(
            id: $id
        ) {
            id
        }
    }
`

export const removeInsurerCategoriesGQL = `
    mutation RemoveInsurerCategories( $ids: [Int!] = [] ){
        removeInsurerCategories(
            ids: $ids
        ) {
            count   
        }
    }
`


export const removeInsurersByIdGQL = `
    mutation RemoveInsurers( $ids: [Int!] = [], $forceDelete: Boolean = false ){
        removeInsurers(
            ids: $ids
            forceDelete: $forceDelete
        ) {
            count   
        }
    }
`

export const updateInsurerCategoryGQL = `
    mutation UpdateInsurerCategory( 
        $id: Int!
        $updatedBy: Int! 
        $name: String
        $active: Boolean
        $categoryTypes: String
    ){
        updateInsurerCategory(updateInsurerCategoryInput: {
            id: $id
            updatedBy: $updatedBy
            name: $name
            active: $active
            categoryTypes: $categoryTypes
        }){
            id
            name
            active
            categoryTypes
        }
    }
`