import {createEvent, createStore} from 'effector'
import {CustomersType, CustomerType} from 'shared/model/customers/Customer.type'
import {TasksType} from 'shared/model/tasks/tasks.type'
import {
    fetchCreateCustomerFx,
    fetchCustomersByIDFx,
    fetchCustomersFx,
    fetchDeleteCustomersFx,
    fetchRemoveCustomerByIdFx,
    fetchUpdateCustomerByIdFx
} from './effects'
import {ObjectType} from 'shared/helpers/types'
import {fetchDeleteTasksFx} from 'store/models/task/effects'
import { $globalState } from 'store/models/global'

interface CustomerState {
    customers: CustomersType,
    total: number,
    selectedCustomer: CustomerType,
    customerTasks: TasksType[],
    isInactiveEnabled: boolean,
    error: string
}

export const $customerState = createStore<CustomerState>({
    customers: [],
    total: 0,
    selectedCustomer: {} as CustomerType,
    customerTasks: [],
    isInactiveEnabled: false,
    error: ''
})

export const setCustomerState = createEvent<ObjectType>({})

$customerState
    .on(fetchCustomersFx.doneData, (state, {count = 0, data = []}) => ({
        ...state,
        total: count,
        customers: data.map((item: CustomerType) => ({
            ...item,
            isBlocked: item?.id === 1 || !!item.tasks?.length || !!item.risks?.length,
            isBlockedMessage: item?.id === 1
                ? `This customer is blocked for the customer portal`
                : item?.tasks?.length && item?.risks?.length
                    ? `This customer has ${item?.tasks?.length} tasks and ${item?.risks?.length} risks attached to it`
                    : item?.tasks?.length
                        ? `This customer has ${item?.tasks?.length} tasks attached to it`
                        : item?.risks?.length
                            ? `This customer has ${item?.risks?.length} risks attached to it`
                            : `This customer has no tasks or risks attached to it`
        }))
    }))
    .on(fetchCustomersFx.failData, (state, error) => ({
        ...state,
        error: error.message
    }))

$customerState
    .on(fetchCustomersByIDFx.done, (state, {result}) => ({
        ...state,
        selectedCustomer: result
    }))
    .on(fetchCustomersByIDFx.failData, (state, error) => ({
        ...state,
        error: error.message
    }))

$customerState
    .on(fetchCreateCustomerFx.done, (state, {result}) => {
        const { defaultPerPage} = $globalState.getState()
        
        return {
        ...state,
        ...(state.total < defaultPerPage && {customers: [...state.customers, result]}),
            total: state.total + 1
        }
    })
    .on(fetchCreateCustomerFx.failData, (state, error) => ({
        ...state,
        error: error.message
    }))

$customerState
    .on(fetchUpdateCustomerByIdFx.done, (state, {result}) => {
        const customers = Array.isArray(state.customers) ? state.customers : []
        return {
            ...state,
            customers: customers?.map(customer => {
                if (customer.id === result.id) {
                    return {
                        ...customer,
                        ...result,
                        active: !customer.active
                    }
                }
                return customer
            })
        }
    })
    .on(fetchUpdateCustomerByIdFx.failData, (state, error) => ({
        ...state,
        error: error.message
    }))

$customerState
    .on(fetchRemoveCustomerByIdFx.done, (state, {result}) => {
        let newCustomers = structuredClone(state.customers)
        if (result.forceDelete) newCustomers = newCustomers.filter(customer => customer.id !== result.id)

        else {
            newCustomers = newCustomers.map(customer => {
                if (customer.id === result.id) {
                    return {
                        ...customer,
                        active: !customer.active
                    }
                }
                return customer
            })
        }

        return ({
            ...state,
            customers: newCustomers,
            total: state.total - 1
        })
    })
    .on(fetchRemoveCustomerByIdFx.failData, (state, error) => ({
        ...state,
        error: error.message
    }))

$customerState
    .on(fetchDeleteCustomersFx.done, (state, {params, result}) => ({
        ...state,
        customers: state.customers.filter(customer => !params.ids.includes(customer.id)),
        total: state.total - result.count
    }))
    .on(fetchDeleteTasksFx.failData, (state, error) => ({
        ...state,
        error: error.message
    }))

$customerState
    .on(setCustomerState, (state, {name, value}) => ({
        ...state,
        [name]: value
    }))
