import {createEffect} from "effector"
import {ObjectType} from "shared/helpers/types"
import {fetchInsurerAvatarByID} from "./services"

export const fetchInsurerAvatarByIDFx = createEffect(async (body: ObjectType) => {
    try {
        const response = await fetchInsurerAvatarByID(body)

        if (response.errors) {
            throw new Error(response.errors?.[0]?.message)
        }
        return response.data.attachmentByID
    } catch (error) {
        if (error instanceof Error) {
            throw new Error(error.message)
        }
    }
})