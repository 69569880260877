import React, {useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import {Box, Grid, Skeleton, Typography} from '@mui/material'
import MainCard from 'shared/ui/MainCard'
import {$globalState} from 'store/models/global'
import {useUnit} from 'effector-react'
import {$userState} from 'store/models/user'
import {$insurerState} from 'store/models/insurer'
import {$customerState} from 'store/models/customer'
import {activeItem} from 'store/models/menu'
import {truncateBy55Symb} from 'shared/helpers/helpers'
import {menuItemsType} from 'menu-items'

interface BreadcrumbsProps {
    navigation: menuItemsType
    title?: string
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({navigation, title, ...others}) => {
    const location = useLocation()

    const {breadCrumbValue} = useUnit($globalState)
    const {selectedCustomer} = useUnit($customerState)
    const {selectedInsurer} = useUnit($insurerState)
    const {selectedUser} = useUnit($userState)

    const getCollapse = (menu: any) => {
        if (menu.children) {
            menu.children.forEach((collapse: any) => {
                if (collapse.type && collapse.type === 'collapse') {
                    getCollapse(collapse)
                } else if (collapse.type === 'item' && location.pathname === collapse.url) {
                    activeItem([collapse.id])
                }
            })
        }
    }

    useEffect(() => {
        navigation.items && navigation?.items?.forEach((menu: any) => {
            if (menu.type && menu.type === 'group') {
                getCollapse(menu)
            }
        })

        return () => {
        }
    }, [location.pathname, navigation.items, selectedCustomer, selectedInsurer, selectedUser])

    return (
        <MainCard border={false} sx={{mb: 0, bgColor: 'transparent'}} {...others} content={false}>
            <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
                <Grid item xs={12}>
                    <Box>
                        <Typography
                            fontWeight="500"
                            variant='h1'
                            sx={{
                                // width: '350px',
                                textAlign: 'start',
                                textDecoration: 'none',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden'
                            }}
                        >
                            {breadCrumbValue?.waiting ?
                                <Skeleton width="180px"/> : truncateBy55Symb(breadCrumbValue?.title || "")}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </MainCard>
    )
}

export default Breadcrumbs