export const Role = {
    ADMIN: 'Admin',
    AGENT: 'Agent',
    CUSTOMER: 'Customer',
    SUPER_ADMIN: 'Super Admin',
    TESTER: 'Tester'
}

export const EditRole = {
    ADMIN: 'Admin',
    AGENT: 'Agent',
    SUPER_ADMIN: 'Super Admin'
}

export const getUsersGQL = `
    query getUsers( $currentUserId: Int, $page: Int = 1, $size: Int = 20, $searchTerm: String, $preferredFields: [String], $orderBy: UserOrderBy, $customerId: Int, $active: Boolean, $roleFilteringOption: RoleFilteringOption, $showUnassigned: Boolean) {
      users(currentUserId: $currentUserId, page: $page, size: $size, searchTerm: $searchTerm, preferredFields: $preferredFields, orderBy: $orderBy, customerId: $customerId, active: $active, roleFilteringOption: $roleFilteringOption, showUnassigned: $showUnassigned) {
        data {
            id
            firstName
            lastName
            role
            phoneNumber
            email
            active
            taskCount
            riskCount
            description
        }
        count
      }
    }
`

export const getAllUsersGQL = `
    query getUsers( $size:Int = 100000, $currentUserId: Int, $preferredFields: [String], $searchTerm: String ) {
        users(size: $size, currentUserId: $currentUserId, preferredFields: $preferredFields, searchTerm: $searchTerm) {
            data {
            id
            firstName
            lastName
            role
            phoneNumber
            email
            active
            taskCount
            riskCount
            description
        }
        count
        }
    }
`

export const getUserByIdGQL = `
    query User($id: Int = 1) {
        user(id: $id) {
            id
            firstName
            lastName
            role
            phoneNumber
            email
            active
            description
            provider
            awsId
        }
    }
`

export const updateUserByIdGQL = `
    mutation UpdateUser( 
        $id: Int!
        $email: String
        $firstName: String
        $lastName: String
        $phoneNumber: String
        $role: Role
        $active: Boolean
        $updatedBy: Int!
        $description: String
    ){
        updateUser(updateUserInput: {
            updatedBy: $updatedBy
            email: $email
            firstName: $firstName
            id: $id
            lastName: $lastName
            role: $role
            phoneNumber: $phoneNumber
            active: $active
            description: $description
        }){
            id
            firstName
            lastName
            role
            phoneNumber
            email
            taskCount
            riskCount
            active
            awsId
            provider
        }
    }
`

export const updateUserAvatarByIdGQL = `
    mutation UpdatePassword( 
        $id: Int!
        $updatedBy: Int!
        $password: String!
        $currentPassword: String!
    ){
        updatePassword(updatePasswordInput: {
            updatedBy: $updatedBy
            id: $id
            password: $password
            currentPassword: $currentPassword 
        }){
            id
        }
    }
`

export const updateUserPasswordByIdGQL = `
    mutation UpdatePassword( 
        $id: Int!
        $updatedBy: Int!
        $password: String!
        $currentPassword: String!
    ){
        updatePassword(updatePasswordInput: {
            updatedBy: $updatedBy
            id: $id
            password: $password
            currentPassword: $currentPassword 
        }){
            id
        }
    }
`

export const createUserGQL = `
    mutation CreateUser(
        $createdBy: Int!
        $email: String!
        $password: String!
        $firstName: String!
        $lastName: String!
        $phoneNumber: String!
        $role: Role
        $active: Boolean
        $description: String
    ) {
        createUser(createUserInput: {
            createdBy: $createdBy
            email: $email
            password: $password
            firstName: $firstName
            lastName: $lastName
            role: $role
            phoneNumber: $phoneNumber
            active: $active
            description: $description
        }) {
            id
            firstName
            lastName
            email
            phoneNumber
        }
    }
`


export const removeUserByIdGQL = `
    mutation RemoveUser( 
        $id: Int!
        $newId: Int
        $forceDelete: Boolean
    ){
        removeUser(
            id: $id
            forceDelete: $forceDelete
            newId: $newId
        ) {
            user { 
                id
                active
            }
        }
    }
`

export const fetchUserAttachmentsByIDGQL = `
    query UserAttachments($id: Int = 1, , $type: AttachmentsType!) {
        attachmentByID(id: $id, type: $type) {
            id
            type
            path
        }
    }
`

export const archiveUserGQL = `
    mutation ArchiveUser( 
        $id: Int!
        $newId: Int
    ){
        archiveUser(
            id: $id
            newId: $newId
        ){
            id
        }
    }
`

export const removeUsersByIdGQL = `
    mutation RemoveUsers( $ids: [Int!] = [], $forceDelete: Boolean = false ){
        removeUsers(
            ids: $ids
            forceDelete: $forceDelete
        ) {
            count
        }
    }
`