import pages from './pages'
import {ObjectType} from 'shared/helpers/types'

export interface menuItemsType {
    items: ObjectType[]
}

const menuItems = {
    items: [pages]
} as menuItemsType

export default menuItems