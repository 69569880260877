import {
    checkAuthGQL,
    getAuthUriGQL,
    logoutGQL,
    resendCodeConfirmationGQL,
    resetPasswordGQL,
    sendCodeGQL,
    setPasswordGQL,
    signInGQL,
    signUpGQL,
    validateCodeGQL,
    verifyUserEmailCodeGQL
} from "queries/auth";
import {Base_Url} from "shared/helpers/api-routes";
import {ObjectType} from "shared/helpers/types";
import {BaseApi} from "utils/Api/baseApi";

export const signIn = (body: ObjectType) => {
    const http = new BaseApi('');
    const gqlPayload = {
        query: signInGQL,
        variables: body
    };
    return http.post(Base_Url, gqlPayload)
}

export const checkAuth = () => {
    const http = new BaseApi('');
    const gqlPayload = {
        query: checkAuthGQL,
    };
    return http.post(Base_Url, gqlPayload)
}

export const logout = () => {
    const http = new BaseApi('');
    const gqlPayload = {
        query: logoutGQL,
    };
    return http.post(Base_Url, gqlPayload)
}

export const sendCode = (body: ObjectType) => {
    const http = new BaseApi('');
    const gqlPayload = {
        query: sendCodeGQL,
        variables: body
    };
    return http.post(Base_Url, gqlPayload)
}

export const validateCode = (body: ObjectType) => {
    const http = new BaseApi('');
    const gqlPayload = {
        query: validateCodeGQL,
        variables: body
    };
    return http.post(Base_Url, gqlPayload)
}

export const authURI = () => {
    const http = new BaseApi('');
    const gqlPayload = {
        query: getAuthUriGQL,
    };
    return http.post(Base_Url, gqlPayload)
}

export const resetPassword = (body: ObjectType) => {
    const http = new BaseApi('');
    const gqlPayload = {
        query: resetPasswordGQL,
        variables: body
    };
    return http.post(Base_Url, gqlPayload)
}

export const signUp = (body: ObjectType) => {
    const http = new BaseApi('');
    const gqlPayload = {
        query: signUpGQL,
        variables: body
    };
    return http.post(Base_Url, gqlPayload)
}

export const sendVerificationEmailCode = (body: ObjectType) => {
    const http = new BaseApi('');
    const gqlPayload = {
        query: verifyUserEmailCodeGQL,
        variables: body
    };
    return http.post(Base_Url, gqlPayload)
}

export const resendCodeConfirmation = (body: ObjectType) => {
    const http = new BaseApi('');
    const gqlPayload = {
        query: resendCodeConfirmationGQL,
        variables: body
    };
    return http.post(Base_Url, gqlPayload)
}

export const setPassword = (body: ObjectType) => {
    const http = new BaseApi('');
    const gqlPayload = {
        query: setPasswordGQL,
        variables: body
    };
    return http.post(Base_Url, gqlPayload)
}